import Meta from "@components/page/meta";

const CategoryPageHead = (props: {
  categoryNoun: string;
  categoryImage?: string;
  url: string;
}) => {
  const title = `PerfectRec: Personalized ${props.categoryNoun} Recommendations`;
  const description =
    "Answer a few questions and get personalized product recommendations. Fast, easy and 100% independent.";
  return (
    <Meta
      title={title}
      description={description}
      image={props.categoryImage}
      url={props.url}
    />
  );
};

export { CategoryPageHead };
