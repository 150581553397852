import CtaCard from "@components/common/cta-card";

const AboutCta = () => {
  return (
    <CtaCard
      cta={{ url: "/about", text: "Read More" }}
      title="Online shopping doesn't have to be a huge waste of time."
      superheading="Our mission"
    >
      Getting good products at the right price point is possible. With
      PerfectRec, we help people to find the right stuff, faster — and make
      their lives better, easier, and a little more perfect.
    </CtaCard>
  );
};

export { AboutCta };
