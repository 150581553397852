import React from "react";

type CardProps = {
  fitWidth?: boolean;
  children: React.ReactNode;
  className?: string;
};

const Card = ({ children, fitWidth = false, className = "" }: CardProps) => {
  return (
    <div
      className={`bg-white p-2 rounded-md border border-keyline-1 ${
        fitWidth ? "w-fit" : "h-full w-full"
      } ${className}`}
    >
      {children}
    </div>
  );
};

export { Card };
