import { SectionHeading } from "@components/common/section-heading";
import SectionContainer, {
  SectionSpacing,
} from "@components/frame/section-container";
import {
  ProductCategoryConfig,
  ProductCategoryConfigMap,
} from "@lib/model/product-category-config";
import {
  AssetType,
  getCategoryProductsUrl,
  getCompareIndexUrl,
  getImageUrl,
  getRecommenderUrl,
  getUnderConstruction,
} from "@lib/utilities/shared-utilities";
import Link from "next/link";
import React, { useContext } from "react";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import Modal from "@components/modal/modal";
import MailchimpForm from "@components/form/mailchimp-form";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import { comingSoonManifest } from "@lib/coming-soon-products";
import { HomePageFeedbackForm } from "@components/form/feedback-forms";
import {
  ReflowModalContext,
  ReflowModalContextProvider,
} from "@components/common/recommender-reflow";
import ImageWithFallback from "@components/common/image-with-fallback";

/**
 * The category cards that appear on the homepage.
 */
const CategoryNavigation = ({
  categories,
}: {
  categories: ProductCategoryConfigMap;
}) => {
  return (
    <div className="bg-white py-px rounded-t-3xl md:rounded-none mt-[-1.75rem] md:mt-0">
      <SectionContainer
        sectionSpacing={SectionSpacing.None}
        className="my-6 md:my-12"
      >
        <SectionHeading className="mb-4 text-center">
          Featured categories
        </SectionHeading>

        <ReflowModalContextProvider>
          <div className="gap-4 grid grid-cols-2 md:grid-cols-4">
            {/* Active categories. */}
            {Object.keys(categories)
              .filter(
                (key) => !getUnderConstruction(categories[key], "landingPages")
              )
              .map((key) => {
                const categoryConfig = categories[key];
                return (
                  <CategoryNavigationCard
                    key={categoryConfig.slug}
                    title={categoryConfig.label}
                    category={categoryConfig}
                    href={
                      getUnderConstruction(categoryConfig, "landingPages")
                        ? undefined
                        : getCategoryProductsUrl(categoryConfig)
                    }
                    image={{
                      src: getImageUrl(
                        categoryConfig.heroImage.src,
                        AssetType.Image,
                        categoryConfig.heroImage.dimensions
                      ),
                      alt: `Image of ${categoryConfig.label}`,
                      ...categoryConfig.heroImage.dimensions,
                    }}
                  />
                );
              })}

            {/* Coming soon categories. Remember to remove categories from the
            manifest when their recommender goes live. */}
            {comingSoonManifest.map((category) => (
              <CategoryComingSoonCard
                title={category.label}
                key={category.label}
                mailchimpTag={category.mailchimpTag}
                image={{
                  src: getImageUrl(
                    category.image.file,
                    AssetType.Image,
                    category.image.dimensions
                  ),
                  ...category.image.dimensions,
                  alt: category.image.alt,
                }}
              />
            ))}
            <CategorySuggestCard
              title={<>Suggest a&nbsp;product</>}
              image={{
                src: getImageUrl("PR_Homepage_SuggestBox.png", AssetType.Image),
                width: 225,
                height: 160,
                alt: "Image showing a suggestion box",
              }}
            />
          </div>
        </ReflowModalContextProvider>
      </SectionContainer>
    </div>
  );
};

const ItemTitle = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <h6 className={`text-base md:text-xl w-full text-left ${className}`}>
    {children}
  </h6>
);

/**
 * A card for active categories, linking to the recommender.
 */
export const CategoryNavigationCard = (props: {
  title: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  category: ProductCategoryConfig;
  image: {
    src: string;
    alt: string;
    width: number;
    height: number;
  };
}) => {
  return (
    <div data-testid={`category-nav-card--${props.category.name}`}>
      <div className="flex flex-col gap-3 relative w-full h-full bg-white p-2 rounded-md border border-keyline-1">
        <ItemTitle>{props.title}</ItemTitle>
        <ImageWithFallback
          className={`object-contain mb-20 lg:mx-auto`}
          {...props.image}
        />
        {props.href && (
          <>
            <a
              href={props.href}
              className="hidden border 2xl:flex gap-2 sm:gap-4 items-center justify-between border-blue-500 py-1.5 px-1.5 sm:px-4 rounded-lg absolute bottom-12 xs:bottom-3 right-[50%] text-sm text-blue-500 font-semibold translate-x-[50%] w-[90%] hover:bg-blue-50"
            >
              Browse&nbsp;all&nbsp;{props.category.pluralNoun}
              <ArrowRightIcon className="h-4 shrink-0" />
            </a>
            <a
              href={props.href}
              className="2xl:hidden border flex gap-2 sm:gap-4 items-center justify-between border-blue-500 py-1.5 px-1.5 sm:px-4 rounded-lg absolute bottom-12 xs:bottom-3 right-[50%] text-sm text-blue-500 font-semibold translate-x-[50%] w-[90%] hover:bg-blue-50"
            >
              Browse&nbsp;all
              <ArrowRightIcon className="h-4 shrink-0" />
            </a>
            <a
              href={getCompareIndexUrl(props.category)}
              className="hidden border 2xl:flex gap-2 sm:gap-4 items-center justify-between border-blue-500 py-1.5 px-1.5 sm:px-4 rounded-lg absolute bottom-2 xs:bottom-3 right-[50%] text-sm text-blue-500 font-semibold translate-x-[50%] w-[90%] hover:bg-blue-50"
            >
              Compare&nbsp;{props.category.pluralNoun}
              <ArrowRightIcon className="h-4 shrink-0" />
            </a>
            <a
              href={getCompareIndexUrl(props.category)}
              className="2xl:hidden border flex gap-2 sm:gap-4 items-center justify-between border-blue-500 py-1.5 px-1.5 sm:px-4 rounded-lg absolute bottom-2 xs:bottom-3 right-[50%] text-sm text-blue-500 font-semibold translate-x-[50%] w-[90%] hover:bg-blue-50"
            >
              Compare
              <ArrowRightIcon className="h-4 shrink-0" />
            </a>
          </>
        )}
      </div>
    </div>
  );
};

/**
 * A card for coming-soon categories, which opens a modal when clicked with a
 * mailing list signup form.
 */
export const CategoryComingSoonCard = ({
  title,
  image,
  mailchimpTag,
}: {
  title: string;
  image: { src: string; alt: string; width: number; height: number };
  mailchimpTag;
}) => {
  const [formOpen, setFormOpen] = useState(false);
  return (
    <>
      <div className="flex flex-col gap-3 relative w-full h-full bg-white p-2 rounded-md border border-keyline-1">
        <ItemTitle className="mt-5 xl:mt-0">{title}</ItemTitle>
        <ImageWithFallback
          className={`object-contain mb-8 lg:mx-auto`}
          {...image}
        />
        <div className="sm:gap-4 absolute bottom-2 xs:bottom-3 right-[50%] translate-x-[50%] w-[90%] lg:w-auto">
          <button
            className="text-sm text-white font-semibold rounded-lg bg-gray-500 hover:bg-gray-600 py-1.5 px-1.5 sm:px-4 w-full"
            onClick={() => {
              eventTracker.track(Events.ModalOpen, {
                explanationType: `Homepage - Waitlist Modal - ${title}`,
              });
              setFormOpen(true);
            }}
          >
            Join waitlist
          </button>
        </div>
        <div className="text-blue-600 font-semibold bg-light-blue py-1 px-1.5 small-caps text-sm absolute right-2 top-0 rounded-b-md">
          Coming soon
        </div>
      </div>

      <Modal
        onClose={() => setFormOpen(false)}
        modalOpen={formOpen}
        omitBottomButtons
      >
        <h3 className="font-sans text-2xl mb-2">Coming soon</h3>
        <p className="text-base">
          We&apos;re working hard to give you the perfect recommendation for
          this product and many more. Enter your email to be the first to hear
          about the next products that become available!
        </p>
        <MailchimpForm tags={[mailchimpTag]} />
      </Modal>
    </>
  );
};

export const CategorySuggestCard = ({
  title,
  image,
}: {
  title: string | JSX.Element;
  image?: { src: string; alt: string; width: number; height: number };
}) => {
  const [formOpen, setFormOpen] = useState(false);
  return (
    <>
      <div className="flex flex-col gap-3 relative w-full h-full bg-white p-2 rounded-md border border-keyline-1">
        <ItemTitle className="">{title}</ItemTitle>
        {image && (
          <ImageWithFallback
            className={`object-contain mb-4 sm:mb-8 mt-4 sm:mt-0 2xl:mt-8  mx-auto`}
            {...image}
          />
        )}
        <div className="sm:gap-4 absolute bottom-2 xs:bottom-3 right-[50%] translate-x-[50%] w-[90%] lg:w-auto">
          <button
            className="text-sm text-white font-semibold rounded-lg bg-gray-500 hover:bg-gray-600 py-1.5 px-1.5 sm:px-4 w-full whitespace-nowrap"
            onClick={() => {
              eventTracker.track(Events.ModalOpen, {
                explanationType: `Homepage - Suggest Modal - ${title}`,
              });
              setFormOpen(true);
            }}
          >
            Suggest a&nbsp;product
          </button>
        </div>
      </div>

      <Modal
        onClose={() => setFormOpen(false)}
        modalOpen={formOpen}
        omitBottomButtons
      >
        <HomePageFeedbackForm hiddenFields={{}} />
      </Modal>
    </>
  );
};

export { CategoryNavigation };
