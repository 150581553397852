import { AboutCta } from "@components/page/about-cta";
import SignupFormBlock from "@components/page/signup-form-block";
import { PageLayout } from "@components/layouts/page-layout";
import { CategoryPageHead } from "./category-page-head";
import { FeaturedIn } from "@components/page/featured-in";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import { IMediaHighlight } from "@lib/model/contentful";

const CategoryPageLayout = (props: {
  categoryNoun: string;
  url: string;
  children: React.ReactNode;
  categoryImage?: string;
  configMap?: ProductCategoryConfigMap;
  mediaHighlights: IMediaHighlight[];
}) => {
  return (
    <PageLayout configMap={props.configMap}>
      <CategoryPageHead
        categoryNoun={props.categoryNoun}
        categoryImage={props.categoryImage}
        url={props.url}
      />

      {props.children}

      <FeaturedIn mediaHighlights={props.mediaHighlights} />
      <hr />
      <AboutCta />
      <SignupFormBlock />
    </PageLayout>
  );
};

export { CategoryPageLayout };
