import { getImageUrl, AssetType } from "@lib/utilities/shared-utilities";
import FiftyFiftySection from "@components/frame/fifty-fifty-container";
import MailchimpForm from "@components/form/mailchimp-form";
import ImageWithFallback from "@components/common/image-with-fallback";

/**
 * The general newsletter signup block and CTA.
 */
const SignupFormBlock = () => {
  return (
    <div className="container mx-auto my-10 md:my-12">
      <div className="bg-gray-100 rounded-3xl">
        <FiftyFiftySection>
          <div className="p-6 md:p-12">
            <h3 className="font-sans mb-2">Stay up to date on new products</h3>
            <p className="mb-6 text-gray-500">
              Get occasional updates about new product releases, interesting
              product news, and exciting PerfectRec features. No spam. We never
              share&nbsp;your&nbsp;email.
            </p>
            <MailchimpForm inlineBtn btnText="Join" />
          </div>
          <div className="flex justify-center h-full w-full">
            <ImageWithFallback
              src={getImageUrl("Email_SignUp_Image.png", AssetType.Image, {
                width: 416,
              })}
              className="object-contain object-bottom"
              alt="Image representing product recommendations"
            />
          </div>
        </FiftyFiftySection>
      </div>
    </div>
  );
};

export default SignupFormBlock;
