import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTransition, animated, useSpring, useSpringRef } from "react-spring";
import { manifest } from "./banner-manifest";

const HeroBannerAnimation = ({
  initialDelay = 0,
}: {
  initialDelay?: number;
}) => {
  const [imgList, setImgList] = useState(manifest[0].images);
  const lastPhoneImgIndex = useMemo(() => imgList.length - 1, [imgList]);
  const [animationStep, setAnimationStep] = useState<number>(0);
  const [animationDone, setAnimationDone] = useState(false);
  const [categoryI, setCategoryIndex] = useState<number>(0);

  const didEnter = useRef(false);

  const phoneImgWidth = 24;
  const phoneImgRightMargin = 12;
  let x = (phoneImgWidth + phoneImgRightMargin) * -1;
  const phoneImgTransition = useTransition(
    imgList.map((data, index) => ({
      ...data,
      x: (x += phoneImgWidth + phoneImgRightMargin),
      marginRight: index === lastPhoneImgIndex ? 0 : phoneImgRightMargin,
    })),
    {
      from: ({ x, marginRight }) => ({
        life: "100%",
        x: 26 * (phoneImgWidth + phoneImgRightMargin + x),
        y: 0,
        marginRight,
        width: `${phoneImgWidth}px`,
      }),
      enter: ({ x }) => {
        didEnter.current = true;
        return {
          x,
        };
      },
      leave: [{ y: 50 }, { width: "0px", marginRight: 0 }],
      update:
        ({ x, marginRight }) =>
        async (next) => {
          await next({ life: "0%" });
          await next({ x, life: "100%", marginRight });
        },
      keys: (item) => item.id,
      delay: didEnter.current ? 0 : initialDelay,
    }
  );

  const animationApi = useSpringRef();
  const animationSpring = useSpring({
    from: { greenCheckOpacity: 0 },
    ref: animationApi,
  });

  const shuffle = (array, except: number[]) => {
    const length = array == null ? 0 : array.length;
    if (!length) {
      return [];
    }
    let index = -1;
    const lastIndex = length - 1;
    const result = [...array];
    while (++index < length) {
      if (!except.includes(index)) {
        const rand =
          index + Math.floor(Math.random() * (lastIndex - index + 1));
        const value = result[rand];
        result[rand] = result[index];
        result[index] = value;
      }
    }
    return result;
  };

  const timeouts = useRef<ReturnType<typeof setTimeout>[]>([]);

  // Clean up all timeouts when we unmount.
  useEffect(() => {
    return () => timeouts.current.forEach((timeout) => clearTimeout(timeout));
  }, []);

  /**
   * Animation queue
   * */
  useEffect(() => {
    if (!animationDone) {
      let copy = [];

      switch (animationStep) {
        // First shuffle/reduce.
        case 0:
          copy = [...imgList];
          copy.splice(4, 1);
          copy.splice(7, 1);
          copy.splice(8, 1);

          timeouts.current.push(
            setTimeout(async () => {
              setImgList(shuffle(copy, [0, 6]));
              setAnimationStep(animationStep + 1);
            }, 2400)
          );
          break;

        // Second shuffle/reduce.
        case 1:
          copy = [...imgList];
          copy.splice(0, 1);
          copy.splice(3, 2);
          copy.splice(5, 1);
          timeouts.current.push(
            setTimeout(() => {
              setImgList(shuffle(copy, [9]));
              setAnimationStep(animationStep + 1);
            }, 2400)
          );
          break;

        // Third shuffle/reduce.
        case 2:
          copy = [...imgList];
          copy.splice(1, 1);
          copy.splice(2, 1);
          copy.splice(5, 2);
          copy.splice(6, 1);
          timeouts.current.push(
            setTimeout(() => {
              setImgList(shuffle(copy.splice(0, 6), []));
              setAnimationStep(animationStep + 1);
            }, 2400)
          );
          break;

        // Fourth shuffle/reduce.
        case 3:
          copy = [...imgList];
          copy.splice(1, 1);
          copy.splice(2, 1);
          copy.splice(3, 1);

          timeouts.current.push(
            setTimeout(() => {
              setImgList(shuffle(copy, []));
              setAnimationStep(animationStep + 1);
            }, 2400)
          );

          break;

        // Done state, fade in green check.
        case 4:
          timeouts.current.push(
            setTimeout(() => {
              animationApi.start({
                to: async (next) => {
                  await next({ greenCheckOpacity: 100 });
                  setAnimationStep(animationStep + 1);
                },
              });
            }, 1200)
          );
          break;

        // Fade out green check and clear list.
        case 5:
          timeouts.current.push(
            setTimeout(() => {
              if (categoryI < manifest.length - 1)
                animationApi.start({
                  to: async (next) => {
                    await next({ greenCheckOpacity: 0 });
                    setImgList([]);
                  },
                });

              setAnimationStep(animationStep + 1);
            }, 1200)
          );
          break;

        // Set new list and reset step counter, or indicate that it is done.
        case 6:
          timeouts.current.push(
            setTimeout(async () => {
              setCategoryIndex((prevIndex) => {
                const newIndex = prevIndex + 1;
                if (manifest[newIndex]?.images) {
                  setImgList(manifest[newIndex].images);
                  setAnimationStep(0);

                  return newIndex;
                } else {
                  setAnimationDone(true);
                  return prevIndex;
                }
              });
            }, 1800)
          );
          break;

        default:
          break;
      }
    }
  }, [animationStep, animationDone, animationApi]);

  return (
    <div>
      <div className="flex mb-4 xl:mb-[2.185rem] mt-0 xl:mt-4.5 lg:overflow-hidden sm:justify-center">
        <animated.div
          className={`inline-block transform transition-all whitespace-nowrap overflow-hidden bg-white rounded-lg py-3 px-2 md:mr-0 min-w-[112px] min-h-[48px]`}
        >
          {phoneImgTransition((styles, item) => (
            <>
              <animated.div
                className="inline-block"
                style={{
                  ...styles,
                  top: "12px",
                  x: 0,
                }}
              ></animated.div>
              <animated.img
                style={{
                  ...styles,
                  top: "12px",
                }}
                className="absolute"
                src={item.src}
                alt="placeholder"
              />
            </>
          ))}
          <animated.div
            className="absolute"
            style={{
              opacity: animationSpring.greenCheckOpacity.to((o) => `${o}%`),
              top: "3px",
              left: "24.5px",
            }}
          >
            <CheckCircleIcon
              className="text-emerald-500"
              style={{
                width: "16px",
                height: "16px",
              }}
            />
          </animated.div>
        </animated.div>
      </div>
    </div>
  );
};

export { HeroBannerAnimation };
