import {
  AssetType,
  getCategoryProductsUrl,
  getImageUrl,
  getRecommenderUrl,
  getUnderConstruction,
} from "@lib/utilities/shared-utilities";
import { LinkButton, PrimaryButton } from "./button";
import { ReactNode, useContext, useState } from "react";
import { ProductCategoryContext } from "contexts/product-category-context";
import {
  ArrowRightCircleIcon,
  ArrowSmallRightIcon,
  BoltIcon,
  FingerPrintIcon,
} from "@heroicons/react/24/outline";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import Modal from "@components/modal/modal";
import Link from "next/link";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import ImageWithFallback from "./image-with-fallback";

/**
 * A message and link to the recommender.
 */
const RecommenderCta = ({
  className = "",
  onClick = () => {},
  ctaMessage = "Get your personalized recommendation",
}: {
  className?: string;
  onClick?: () => void;
  ctaMessage?: ReactNode;
}) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  if (getUnderConstruction(productCategoryConfig, "recommender")) {
    return null;
  }

  return (
    <div
      className={`rounded-xl border border-slate-900 bg-panel-blue-1 p-4 ${className}`}
    >
      <div className="font-semibold mb-2 text-center">
        Try the PerfectRec decision engine to get your personalized{" "}
        {productCategoryConfig.noun} recommendation
      </div>
      <LinkButton
        href={getRecommenderUrl(productCategoryConfig)}
        onClick={onClick}
      >
        {ctaMessage}
      </LinkButton>
    </div>
  );
};

/**
 * A more elaborate recommender CTA with a modal panel that lists category links.
 */
export const RecommenderCtaAdvanced = ({
  categories,
}: {
  categories: ProductCategoryConfigMap;
}) => {
  const bullets = [
    { icon: BoltIcon, text: "Just one minute of easy Q&A" },
    { icon: FingerPrintIcon, text: "Personalized picks from top experts" },
  ];

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="py-4 px-4 sm:py-8 bg-panel-blue-1 my-10">
      <div className="sm:max-w-[572px] mx-auto">
        <h3 className="text-base sm:text-xl text-center mb-3 font-sans">
          Try PerfectRec to get your personalized recommendation
        </h3>
        <ImageWithFallback
          src={getImageUrl("product-image.png", AssetType.Image, {
            height: 350,
          })}
          alt="Product image"
          width={594}
          height={350}
          className="h-[160px] sm:h-[250px] my-3 object-contain"
        />
        <ul className="flex flex-col gap-3 my-3 sm:mt-8 sm:mb-12 sm:max-w-[350px] mx-auto">
          {bullets.map(({ icon, text }, i) => (
            <li className="flex justify-start gap-3 items-center" key={i}>
              <IconTile icon={icon} />
              <div className="text-xs sm:text-sm text-gray-900 font-serif">
                {text}
              </div>
            </li>
          ))}
        </ul>
        <PrimaryButton
          className="w-full"
          variant="solid"
          onClick={() => {
            eventTracker.track(Events.ModalOpen, {
              explanationType: `Blog post - Opened Get Recommendations Modal`,
            });
            setModalOpen(true);
          }}
        >
          <span>Get started</span>
          <ArrowRightCircleIcon className="stroke-white h-5 w-5" />
        </PrimaryButton>
        <RecommenderCtaModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          categories={categories}
          trackingContext="post"
        />
      </div>
    </div>
  );
};

/**
 * A styled wrapper for a heroicon.
 */
const IconTile = ({ icon }: { icon?: React.FC<{ className: string }> }) => {
  const Icon = icon;

  return (
    <Icon className="h-8 w-8 border border-gray-500 rounded-md p-1.5 bg-white flex-shrink-0" />
  );
};

/**
 * A modal containing a list of category recommender CTAs.
 */
export const RecommenderCtaModal = ({
  open,
  onClose,
  categories,
  trackingContext,
}: {
  open: boolean;
  onClose: () => void;
  categories: ProductCategoryConfigMap;
  trackingContext: string;
}) => {
  return (
    <Modal
      modalOpen={open}
      onClose={onClose}
      title="What product category are you looking for?"
      omitBottomButtons={true}
      variant="medium"
    >
      <div className="flex flex-col gap-2">
        {Object.keys(categories)
          .filter(
            (category) =>
              !getUnderConstruction(categories[category], "landingPages")
          )
          .map((category) => (
            <Link
              href={getCategoryProductsUrl(categories[category])}
              key={categories[category].name}
              onClick={() => {
                eventTracker.track(Events.ModalRecommenderCtaClick, {
                  category,
                  context: trackingContext,
                });
              }}
            >
              <div className="border border-gray-500 rounded-lg flex justify-between items-center gap-6 py-3 px-4 h-18 hover:bg-gray-50">
                <div className="flex gap-3 items-center">
                  <ImageWithFallback
                    className="w-12"
                    src={getImageUrl(
                      categories[category].heroImage.src,
                      AssetType.Image,
                      { width: 48 }
                    )}
                    alt={`Image of ${categories[category].label}`}
                    {...categories[category].heroImage.dimensions}
                  />
                  <h4 className="text-base font-semibold capitalize">
                    {categories[category].pluralNoun}
                  </h4>
                </div>
                <ArrowSmallRightIcon className="h-4 w-4 stroke-2" />
              </div>
            </Link>
          ))}
      </div>
    </Modal>
  );
};

export default RecommenderCta;
