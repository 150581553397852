import React from "react";
import SectionContainer from "@components/frame/section-container";
import { SectionSpacing } from "@components/frame/section-container";
import { Card } from "./card";
import { SectionHeading } from "./section-heading";

export const ThreeUpFeature = (props: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <SectionContainer
      className="my-2 lg:my-12"
      sectionSpacing={SectionSpacing.None}
    >
      <SectionHeading className="text-center my-6">
        {props.title}
      </SectionHeading>
      <div className="lg:grid grid-cols-3 gap-18 my-4 lg:my-8">
        {props.children}
      </div>
    </SectionContainer>
  );
};

export const ThreeUpFeatureSection = (props: {
  title: string;
  icon?: React.FC<{ className: string }>;
  children: React.ReactNode;
}) => {
  return (
    <div className="mb-8 lg:mb-0 px-2 lg:px-0">
      <div className="flex lg:block items-start lg:items-center">
        {props.icon && (
          <Card fitWidth>
            {React.createElement(props.icon, { className: "h-6 w-6" })}
          </Card>
        )}
        <div className="ml-3 lg:ml-0">
          <h6 className="text-base md:text-xl mb-2.5 lg:mt-6 lg:mb-4 font-serif">
            {props.title}
          </h6>
          <p className="text-gray-500">{props.children}</p>
        </div>
      </div>
    </div>
  );
};
