import { v4 as uuidv4 } from "uuid";

const phoneImages = [];

for (let i = 0; i <= 25; i++) {
  phoneImages.push({
    id: uuidv4(),
    src: `./images/hero-animation/phones/phone-image-${i}.png`,
  });
}

const tvsImages = [];

for (let i = 0; i <= 25; i++) {
  tvsImages.push({
    id: uuidv4(),
    src: `./images/hero-animation/tvs/tv-image-${i}.png`,
  });
}

const laptopImages = [];

for (let i = 0; i <= 25; i++) {
  laptopImages.push({
    id: uuidv4(),
    src: `./images/hero-animation/laptops/laptop-image-${i}.png`,
  });
}

// This is one of the few places where recommender urls don't use the canonical
// url function, because we aren't yet loading category data on the homepage.
const manifest = [
  {
    name: "phone",
    images: phoneImages,
    url: "/electronics/best-phones/recommendation",
    strings: [
      "Best phone for taking amazing photos",
      "with a large screen",
      "and great battery life",
      "for under $800",
    ],
  },
  {
    name: "TV",
    images: tvsImages,
    url: "/electronics/best-tvs/recommendation",
    strings: [
      "Best TV for watching sports",
      "that’s at least 70”",
      "and doesn’t get much glare",
      "for under $1,500",
    ],
  },
  {
    name: "laptop",
    images: laptopImages,
    url: "/electronics/best-laptops/recommendation",
    strings: [
      "Best laptop for video editing",
      "that’s light and portable",
      "and has great battery life",
      "for under $2,000",
    ],
  },
];

export { manifest };
