import React from "react";
import SectionContainer, {
  SectionSpacing,
} from "@components/frame/section-container";
import FiftyFiftySection from "@components/frame/fifty-fifty-container";
import { SectionHeading } from "./section-heading";
import { LinkButton } from "./button";

type CtaCardProps = {
  cta: {
    url: string;
    text: string;
  };
  title: string;
  superheading?: string;
  children: React.ReactNode;
  className?: string;
};

const CtaCard = (props: CtaCardProps) => {
  return (
    <SectionContainer
      sectionSpacing={SectionSpacing.None}
      className="my-6 md:my-12"
    >
      <FiftyFiftySection className="gap-x-32">
        <div>
          {props.superheading && (
            /* Not a typical h6 - added one-off rules per design */
            <h6 className="text-lg leading-[1.438rem] md:leading-[1.625rem] font-semibold md:text-[1.313rem] mb-4">
              {props.superheading}
            </h6>
          )}
          <SectionHeading className="lg:my-0 my-4">
            {props.title}
          </SectionHeading>
        </div>
        <div>
          <p className="text-gray-500">{props.children}</p>
          <LinkButton href={props.cta.url}>{props.cta.text}</LinkButton>
        </div>
      </FiftyFiftySection>
    </SectionContainer>
  );
};

export default CtaCard;
