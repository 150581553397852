import SectionContainer, {
  SectionSpacing,
} from "@components/frame/section-container";
import { HeroBannerAnimation } from "./hero-banner-animation";
import { useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import { RecommenderCtaModal } from "@components/common/recommender-cta";

const GetStartedModalCta = ({
  categories,
}: {
  categories: ProductCategoryConfigMap;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <button
        className="rounded-xl py-3 sm:py-6 px-4 sm:px-8 bg-emerald-500 hover:bg-emerald-600 flex gap-3 mt-4 sm:my-8 mx-auto items-center border-2 border-white w-full sm:w-auto"
        onClick={() => {
          eventTracker.track(Events.ModalOpen, {
            explanationType: `Homepage - Get Recommendations Modal`,
          });
          setModalOpen(true);
        }}
      >
        <span className="text-white text-2xl font-semibold">
          Start Browsing
        </span>
        <ArrowRightCircleIcon className="h-7 w-7 stroke-white" />
      </button>

      <RecommenderCtaModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        categories={categories}
        trackingContext="homepage"
      />
    </>
  );
};

const HeroBanner = ({
  categories,
}: {
  categories: ProductCategoryConfigMap;
}) => {
  const delay = 600;

  return (
    <div className="bg-blue-900 py-px overflow-hidden">
      <SectionContainer
        sectionSpacing={SectionSpacing.None}
        className="my-6 md:my-12 pb-7 md:pb-0 text-white sm:text-center"
      >
        <h1 className="text-3xl sm:text-5xl">
          Find the perfect product for you
        </h1>
        <h2 className="font-sans font-normal text-xl sm:text-2xl mt-4 mb-6">
          Browse and compare detailed information on hundreds of products.
        </h2>
        <HeroBannerAnimation initialDelay={delay} />
        <GetStartedModalCta categories={categories} />
      </SectionContainer>
    </div>
  );
};

export { HeroBanner };
