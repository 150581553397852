import { CategoryPageLayout } from "@components/layouts/category-page-layout/category-page-layout";
import { CategoryNavigation } from "@components/page/category-navigation";
import { HeroBanner } from "@components/page/hero-banner";
import { HowItWorks } from "@components/page/how-it-works";
import { TrackingPageProps } from "@lib/page-props-tracking-interface";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import errorTracker from "@lib/tracking/error-tracker";
import { usePageScrollTracking } from "@lib/hooks/scrollTracking/use-page-scroll-tracking";
import { getAllMediaHighlights } from "@server/contentful";
import { IMediaHighlight } from "@lib/model/contentful";
import { getServerCacheProductCategoryConfig } from "@lib/fetching/product-category-config";

const Index = (
  pageProps: TrackingPageProps & {
    configMap: ProductCategoryConfigMap;
    mediaHighlights: IMediaHighlight[];
  }
) => {
  usePageScrollTracking({ page: "Index" });

  return (
    <CategoryPageLayout
      configMap={pageProps.configMap}
      categoryNoun="Product"
      mediaHighlights={pageProps.mediaHighlights}
      url=""
    >
      <HeroBanner categories={pageProps.configMap} />
      <CategoryNavigation categories={pageProps.configMap} />
      <HowItWorks />
    </CategoryPageLayout>
  );
};

export const getServerSideProps = errorTracker.wrapFunction(async () => {
  const mediaHighlightsPromise = getAllMediaHighlights();
  const configMapPromise = getServerCacheProductCategoryConfig();

  const [mediaHighlights, configMap] = await Promise.all([
    mediaHighlightsPromise,
    configMapPromise,
  ]);

  return {
    props: {
      component: "homepage",
      configMap,
      mediaHighlights,
    },
  };
});

export default Index;
