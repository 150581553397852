import { Card } from "@components/common/card";
import {
  ThreeUpFeature,
  ThreeUpFeatureSection,
} from "@components/common/three-up-feature";
import SectionContainer, {
  SectionSpacing,
} from "@components/frame/section-container";
import { NoDollarIcon } from "@components/svgs/no-dollar";
import {
  FingerPrintIcon,
  ListBulletIcon,
  ScaleIcon,
  TableCellsIcon,
  TrophyIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

const HowItWorks = () => {
  return (
    <div className={"py-px bg-gray-100"}>
      <ThreeUpFeature title="Why PerfectRec is the best place to browse and compare products">
        <ThreeUpFeatureSection title="Accurate Data" icon={TableCellsIcon}>
          Our product stats are gathered and verified by a team of human
          experts, ensuring you get reliable insights and accurate pricing.
        </ThreeUpFeatureSection>
        <ThreeUpFeatureSection title="Real-World Evaluations" icon={UserIcon}>
          We score products based on how they would be used in the real world to
          provide you with more relevant comparisons.
        </ThreeUpFeatureSection>
        <ThreeUpFeatureSection title="Easy Comparisons" icon={ListBulletIcon}>
          Our intuitive tools make finding and comparing the best products
          straightforward for everyone.
        </ThreeUpFeatureSection>
      </ThreeUpFeature>
      <SectionContainer
        className="my-2 md:my-12"
        sectionSpacing={SectionSpacing.None}
      >
        <Card>
          <div className="flex md:items-center px-0 py-4 md:px-4">
            <NoDollarIcon className="h-10 w-10 flex-shrink-0 mr-3 md:mr-4" />
            <div>
              <h6 className="new-h6 my-2.5 md:mt-0 md:mb-1 font-serif">
                We are 100% independent
              </h6>
            </div>
          </div>
        </Card>
      </SectionContainer>
    </div>
  );
};

export { HowItWorks };
