const NoDollarIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    className={`${props.className || ""}`}
  >
    <path
      stroke="#0F172A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      d="M20 10v20m-5-4.697 1.465 1.099c1.952 1.464 5.118 1.464 7.07 0 1.953-1.465 1.953-3.84 0-5.304C22.56 20.366 21.28 20 20 20c-1.208 0-2.416-.366-3.338-1.098-1.844-1.465-1.844-3.84 0-5.304 1.843-1.464 4.832-1.464 6.676 0l.691.55M35 20"
    />
    <circle cx="20" cy="20" r="15" stroke="#FFD6CF" strokeWidth="2.5" />
    <path stroke="#FFD6CF" strokeWidth="2.5" d="m9.218 9.116 21.667 21.667" />
  </svg>
);

export { NoDollarIcon };
